@media screen and (max-width: 1620px) {
	.like {
		left: 150px !important;
	}

	.calendar {
		right: 200px !important;
	}
}

@media screen and (max-width: 1250px) {
	.like {
		left: 50px !important;
		top: 410px !important;
	}

	.calendar {
		right: 140px !important;
	}
}

@media screen and (max-width: 1140px) {
	.landing h1 {
		margin-top: 150px;
		font-size: 75px;
	}

	.landing span {
		font-size: 75px;
	}

	.like {
		top: 380px !important;
	}

	.calendar {
		right: 120px !important;
	}
}

@media screen and (max-width: 1020px) {
	.landing h1 {
		font-size: 70px;
	}

	.landing span {
		font-size: 70px;
	}

	.landing p {
		max-width: 650px;
	}

	.like {
		left: 20px !important;
	}
}

@media screen and (max-width: 945px) {
	.landing h1 {
		font-size: 65px;
	}

	.landing span {
		font-size: 60px;
	}
}

@media screen and (max-width: 880px) {
	.landing h1 {
		font-size: 60px;
	}

	.landing span {
		font-size: 55px;
	}

	.landing p {
		max-width: 550px;
	}
}

@media screen and (max-width: 815px) {
	.landing h1 {
		font-size: 55px;
	}

	.landing p {
		max-width: 500px;
	}

	.like {
		width: 100px;
		height: 100px;
	}

	.calendar {
		right: 50px !important;
	}
}

@media screen and (max-width: 750px) {
	.landing h1 {
		font-size: 60px !important;
		margin-top: 80px !important;
		/* width: 590px;
        margin: auto; */
		display: flex;
		flex-direction: column;
	}

	.landing span {
		font-size: 55px !important;
	}

	.landing p {
		max-width: 490px;
	}

	.like {
		top: 350px !important;
	}

	.calendar {
		width: 80px;
		height: 80px;
		right: 20px !important;
		top: 540px !important;
	}
}

@media screen and (max-width: 750px) {
	.landing h1 {
		font-size: 55px !important;
	}

	.landing span {
		font-size: 50px !important;
	}

	.landing p {
		max-width: 450px;
	}

	.like {
		width: 80px;
		height: 80px;
		top: 320px !important;
	}
}

@media screen and (max-width: 500px) {

	.landing h1,
	span {
		font-size: 50px !important;
	}

	.landing p {
		max-width: 400px;
	}
	.file {
		top: -18px !important;
		right: 5px !important;
	}

	.like {
		width: 80px;
		height: 80px;
		top: 305px !important;
	}
}

@media screen and (max-width: 400px) {
	.landing h1 {
		margin-top: 50px !important;
	}

	.like {

		left: 10px !important;
		top: 110px !important;
		opacity: 0.7;
	}

	.calendar {
		width: 80px;
		height: 80px;
		right: 5px !important;
		top: 370px !important;
	}

	.file {
		width: 150px;
		height: 150px;
	}

	.landing p {
		font-size: 16px;
	}


}
