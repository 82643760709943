.checkout{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: radial-gradient(at 40% 20%,hsla(28, 100%, 74%, 1) 0px,transparent 50%),
		radial-gradient(at 80% 0%, hsla(189, 100%, 56%, 1) 0px, transparent 50%),
		radial-gradient(at 0% 50%, hsla(355, 100%, 93%, 1) 0px, transparent 50%),
		radial-gradient(at 80% 50%, hsla(340, 100%, 76%, 1) 0px, transparent 50%),
		radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
		radial-gradient(at 80% 100%, hsla(242, 100%, 70%, 1) 0px, transparent 50%),
		radial-gradient(at 0% 0%, hsla(343, 100%, 76%, 1) 0px, transparent 50%);
		animation:  animate 5s ease-in infinite alternate;

}

.checkout ol{
    margin-top: 20px;
    margin-bottom: 70px;
}

.checkout li {
	font-size: 18px;
	color: #f0f0f0;
	text-align: left;	
    font-weight: 500;
	max-width: 710px;
	margin: auto;
	margin-top: 10px;
}

.checkout h1{
    margin-top: 0;
    margin-bottom: 20px;
    
}
.checkout span{    
    font-weight: bold;
}
.checkoutspan{
    text-decoration: line-through;
    font-weight: bold;
}

.checkout .mainBtn{
    padding: 20px 40px;
}

.checkout .container{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

@keyframes animate { 
	0%{background-color: hsl(137, 100%, 50%);}
	25%{background-color: hsl(228, 100%, 50%);}
	50%{background-color: hsl(278, 100%, 50%);}
	75%{background-color: hsl(320, 100%, 50%);}
	100%{background-color: hsl(22, 100%, 50%);}
  }
