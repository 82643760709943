.z6{
    position: relative;
    z-index: 6;
}

.burgerMenu{
    display: none !important;
}

.burger-menu{
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items:  flex-start;
    justify-content: space-between;
    cursor: pointer;
    /* background-color: blue; */
}

.burger-bar{
    background-color: #FF001F;
    height: 3px;
    width: 40px;
    border-radius: 5px;
    
}

.menu{
    display: none !important;
    width: 50%;
    height: 100vh;
    background-color: #F0F0F0;
    float: right;
    position: absolute;
    right: -20px;
    top: 0;
    z-index: -1;
    
}

.burgerLinks{
    
    height: 300px;
    margin-top: 150px;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 50px !important;
    
    
}

.burgerLinks a{
    text-decoration: none;
    color: #042AA1;
    font-size: 18px;
    font-weight: 900;
}

.hidden{
    display: none !important;
    transition: ease-out 0.2s;
}

.visible{
    display: block !important;
    transition: ease-out 0.2s;
}

.burger-bar.clicked:nth-child(1){
    transform: rotate(45deg) translate( 0.75em, 0em);
    transition: ease-out 0.2s;
}

.burger-bar.clicked:nth-child(2){
    transform: scale(0);
    transition: ease-out 0.2s;
}
.burger-bar.clicked:nth-child(3){
    transform: rotate(135deg) translate(-0.7em, 0em);
    transition: ease-out 0.2s;
}

.burger-bar.unclicked{
    transform: rotate(0), translate(0);
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media screen and (max-width: 780px) {
.burgerMenu{
    display: flex !important;
    padding: 10px 0px;
    height: 40px;
}

.menu{
    display: block;
}
}