.vual{
	background-color: rgba(0, 0, 0, 0.90);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;
}

.quotemodal-window {
	position: fixed;
	z-index: 99;
	/* background-color: rgba(0, 0, 0, 0.90); */
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.3s;
  }



.quotemodal-window .modal-box{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 999;
}

.quotemodal-window   .modal-close {
	cursor: pointer;
	color: #aaa;
	line-height: 50px;
	font-size: 90%;
	position: absolute;
	right: 50px;
	text-align: center;
	top: -110px;
	width: 70px;
	text-decoration: none;
	background: none;
	outline: none;
	border: none;
    z-index: 99;
  }

  .quotehead{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
  }

  .quotehead> p,h4{
    color: #f1f1f1 !important;
  }

  .quotehead p{
    
    font-weight: 700;
  }
