.darkSection {
	padding: 100px 0;
	background-color: #101010;
	margin-top: 150px;
	min-height: 100vh;
}

.darkSection h2 {
	color: #f0f0f0;
	text-align: center;
}

.meshImg {
	position: absolute;
	opacity: 0.2;
	filter: blur(150px);
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
}

.card {
	z-index: 1;
	position: relative;
	width: 100%;
	max-width: calc(100% / 4 - 10px);
	padding: 20px;
	border: 1.5px solid rgba(223, 223, 223, 0.23);
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 10px;
	box-sizing: border-box;
	overflow: hidden;
}

.w100 {
	width: 100%;
	margin: 0;
}

.cardHead,
.cardBody {
	z-index: 2;
	position: relative;
}

.cardGlass {
	/* background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(10px); */
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.cardHead {
	margin-bottom: 10px;
}

.cardHead > h5 {
	color: #f0f0f0;
	line-height: 120%;
}

.cardBody > p {
	color: #f0f0f0;
	margin-bottom: 20px;
}

.sliderSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 200px;
}

.sliderleftSide {
	/* max-width: 530px; */
	width: 100%;
	/* padding-left: 360px; */
}

.sliderleftSide h2 {
	font-size: 45px;
	font-family: "SF UI Display";
	text-transform: uppercase;
	color: #f0f0f0;
	font-weight: 900;
	line-height: 100%;
	text-align: left;
}

.sliderRightside {
	max-width: 920px;
	width: 100%;
	position: absolute;	
	right: -350px;
	cursor: grab;

}

.sliderCard {
	max-width: 450px;
	min-height: 265px;
	width: 100%;
	padding: 20px;
	color: #f0f0f0;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(223, 223, 223, 0.23);
	backdrop-filter: blur(75px);
	border-radius: 10px;
	margin-right: 20px;

}

.sliderCard p {
	margin-top: 10px;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.sliderCard h3{
	text-transform: uppercase;
	margin-top: 20px;
}

.cursor {
	width: 120px;
	height: 120px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(223, 223, 223, 0.23);
	backdrop-filter: blur(25px);
	border-radius: 50%;
	color: #f0f0f0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	pointer-events: none;
}

.cursor::after {
	content: "swipe";
	display: flex;
	justify-content: center;
	padding-top: 40%;
}

.slick-slide > div {
	margin-right: 20px;
	max-width: 450px !important;
	width: 100%;
}

.modal-window {
	position: fixed;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.85);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.3s;
  }

  .modal-window > .modal-box {
	color: #f0f0f0;
	width: 400px;
	position: relative;
	text-align: left;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	border: 1.5px solid rgba(223, 223, 223, 0.23);
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 10px;
  }
  .modal-window header {
	font-weight: bold;
  }
  .modal-window h1 {
	font-size: 150%;
	margin: 0 0 15px;
	color: #f0f0f0;
	text-align: left;
  }
  
  .modal-close {
	cursor: pointer;
	color: #aaa;
	line-height: 50px;
	font-size: 90%;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 70px;
	text-decoration: none;
	background: none;
	outline: none;
	border: none;
  }
  .modal-close:hover {
	color: red;
  }

  .modal-close:target .modal-window{
	visibility: hidden !important;
	opacity: 0;
  }

  .modal-window > div {
	border-radius: 1rem;
  }
  
  .modal-window div:not(:last-of-type) {
	margin-bottom: 15px;
  }
  
  .avatar{
	vertical-align: middle;
	width: 50px;
	height: 50px;
	border-radius: 50%;
  }