@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url(./assets/fonts/style.css);

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Quicksand", sans-serif;
	scroll-behavior: smooth;
}

/* body, html{
	overflow-x: hidden;
} */

body::-webkit-scrollbar {
	-webkit-appearance: none;
	display: none;
	scroll-behavior: smooth;
}

body {
	background-image: url("./assets/noise.png");
	background-repeat: repeat;
	background-size: contain;
	overflow-x: hidden;
}

.container {
	width: 100%;
	max-width: 1200px;
	margin: auto;
}

.wrapper {
	display: flex;
	justify-content: space-between;
}

.z2 {
	z-index: 2;
	position: relative;
}

.alignCenter {
	align-items: center;
}

::placeholder {
	color: #042aa1;
	font-size: 15px;
	font-weight: bold;
}

h1 {
	font-size: 85px;
	font-family: "SF UI Display";
	text-transform: uppercase;
	color: #042aa1;
	font-weight: 900;
	line-height: 100%;
	width: 100%;
	text-align: center;
	margin-top: 175px;
}

h2 {
	font-size: 45px;
	font-family: "SF UI Display";
	text-transform: uppercase;
	color: #042aa1;
	font-weight: 900;
	line-height: 100%;
}

h4 {
	font-size: 30px;
	font-family: "SF UI Display";
	text-transform: uppercase;
	color: #042aa1;
	font-weight: 700;
	line-height: 100%;
}

h5 {
	font-size: 20px;
	font-family: "SF UI Display";
	text-transform: uppercase;
	color: #042aa1;
	font-weight: 700;
	line-height: 100%;
}

h6 {
	font-size: 18px;
	font-family: "SF UI Display";
	text-transform: uppercase;
	color: #042aa1;
	font-weight: 700;
	line-height: 100%;
}

h1 > span {
	color: #f0f0f0;
	font-size: 85px;
	font-family: "SF UI Display";
	text-transform: uppercase;
}

.btn {
	outline: none;
	border: none;
	font-size: 16px;
	border-radius: 5px;
	color: #fff;
	font-weight: bold;
}

.btn:hover {
	cursor: pointer;
}

.mainBtn {
	background-color: #ff001f;
	padding: 10px 35px;
	transition: 0.25s;
	margin-left: 15px;
}

.w100 {
	width: 100%;
	margin: 0;
}

.mt70 {
	margin-top: 70px;
}

.mt150 {
	margin-top: 150px;
}

.noise {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.bag {
	position: absolute;
	left: -70px;
	bottom: -100px;
}

.like {
	position: absolute;
	top: 390px;
	left: 300px;
	z-index: 3;
}

.calendar {
	position: absolute;
	top: 490px;
	right: 400px;
}

.file {
	position: absolute;
	top: 100px;
	right: 220px;
	z-index: 2;
}



