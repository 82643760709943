@media screen and (max-width: 1050px) {
#price .wrap{
    flex-wrap: wrap;
}

.priceCrad{
    width: calc(100% / 3 - 20px);
}



}

@media screen and (max-width: 980px) {

     .priceCrad{
        width: calc(100% / 2 - 20px);
        margin-top: 30px;
    }

    
    }

    
@media screen and (max-width: 710px) {

     .priceCrad{
        width: calc(100% / 1 - 20px);
        margin-top: 30px;
    }

    
    }

    

    @media screen and (max-width: 560px) {



        
        .priceHead h4{
            font-size: 32px;
        }

    
        .priceCrad{
            width: calc(100% / 1 - 10px);
            margin-top: 30px;
        }

      

        #price.mt150{
            margin-top: 100px;
        }
        
        .priceHead h4{
            font-size: 32px;
        }

        }

        @media screen and (max-width: 500px) {
            #price h2{
                font-size: 40px;
            }

                    #price.mt150{
            margin-top: 50px;
        }
        }