.landing {
	width: 100%;
	height: 760px;
	position: relative;
	
}

.mesh {
	background-image: radial-gradient(at 40% 20%,hsla(28, 100%, 74%, 1) 0px,transparent 50%),
		radial-gradient(at 80% 0%, hsla(189, 100%, 56%, 1) 0px, transparent 50%),
		radial-gradient(at 0% 50%, hsla(355, 100%, 93%, 1) 0px, transparent 50%),
		radial-gradient(at 80% 50%, hsla(340, 100%, 76%, 1) 0px, transparent 50%),
		radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
		radial-gradient(at 80% 100%, hsla(242, 100%, 70%, 1) 0px, transparent 50%),
		radial-gradient(at 0% 0%, hsla(343, 100%, 76%, 1) 0px, transparent 50%);
		animation:  animate 5s ease-in infinite alternate;

}

.landing p {
	font-size: 18px;
	color: #f0f0f0;
	text-align: center;
	font-weight: bold;
	line-height: 130%;
	max-width: 710px;
	margin: auto;
	margin-top: 20px;
}



@keyframes animate { 
	0%{background-color: hsl(137, 100%, 50%);}
	25%{background-color: hsl(228, 100%, 50%);}
	50%{background-color: hsl(278, 100%, 50%);}
	75%{background-color: hsl(320, 100%, 50%);}
	100%{background-color: hsl(22, 100%, 50%);}
  }


