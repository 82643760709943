.mainForm{
	width: 1260px;
	margin: auto;
	margin-top: -120px;
	z-index: 3;
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 5px 65px rgba(4, 42, 161, 0.15);
	background-color: rgba(223, 223, 223, 0.31);
	border: 1.5px solid rgb(223 223 223 / 23%);	
	backdrop-filter: blur(2px);
}

.glass{
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.quotehead{
	color: #042AA1 !important; 
	font-family: 'SF UI Display';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 100%;
	text-transform: uppercase;
}

.formBody{
	z-index: 4;
	position: relative;
	padding: 30px;
}

.formBodyOne{
	width: 100%;
	max-width: 400px;
	margin-top: 20px;
}

.formBodyOne input, select, textarea{
	width: 100%;
	height: 50px;
	background-color: #e4e4e4;
	padding: 15px;
	border-radius: 5px;
	border: none;
	outline: none;
	color: #042AA1 !important;
	font-size: 15px;
	font-weight: bold;
}

.inputMt10{
	margin-top: 10px;
}

.formBodyOne textarea{	
	resize: none;
}

.formBodyOne button{
	width: 100%;
	height: 50px;
	margin-top: 10px;
	margin-left: 0;
}

.formBodyOne:last-child{
	max-width: 380px;
}

.infowrap input{
	width: calc(100% / 2 - 5px);
}

.formBodyOne input[type=date] {
	padding-right: 2px !important;
	margin-right: 10px;	
	width: 100%;
	height: 50px;
}