@media screen and (max-width: 1220px) {

.container{
    padding: 0 20px;
}
  

}


@media screen and (max-width: 780px) {

    .navItems{
     display: none;
    }
      
    
    }
    